import React from "react";

import { Container, SEOPage } from "../components";

export default function Home({ data }) {

  //On page SEO
  const pageSEO = {
    title: "Home",
    url: `/`,
    type: "website"
  }

  return (
    <Container page="content">

      {/* Page meta data (title, description, etc.)*/}
      <SEOPage
        title={pageSEO.title}
        url={pageSEO.url}
        type={pageSEO.type}
      />

    </Container >
  )
}